<template>
  <div class="text-center mt-5 mb-4">
    <h2>Dashboard</h2>
  </div>

    <div class="container mt-4" v-if="usersStore.users && usersStore.users.length>0">
      <div class="card mb-4">
        <div class="card-header">
        <b>Usuarios</b>
        <input type="text" class="form-control ms-3 d-inline-block" style="width: auto;" v-model="searchUserQuery" placeholder="Buscar Usuarios...">
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Nombre de Usuario</th>
              <th>Email</th>
              <th>Direccion</th>
              <th class="text-end">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in usersStore.users" :key="user.id">
              <td>{{ user.id }}</td>
              <td>{{ user.name }}</td>
              <td>{{ user.username }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.address }}</td>
              <td class="text-end"> <!-- Alineación a la derecha para los botones -->
                  <router-link :to="{ name: 'UserDetails', params: { id: user.id } }" class="btn btn-primary me-1">Ver Detalles</router-link>
                  <router-link :to="{ name: 'UserFormAdmin', params: { id: user.id } }" class="btn btn-warning me-1">Editar</router-link>
                  <button class="btn btn-danger" @click="confirmDeleteUser(user.id)">Eliminar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card-footer d-flex justify-content-end">
        <nav aria-label="Page navigation">
          <ul class="pagination" v-if="!searchUserQuery">
            <li class="page-item mt-2" :class="{ disabled: !usersStore.prevPage }">
              <button class="page-link" @click="usersStore.fetchUsers(usersStore.currentPage - 1)" :disabled="!usersStore.prevPage">Anterior</button>
            </li>
            <li class="page-item mt-2" v-for="n in usersStore.totalPages" :key="n" :class="{ active: n === usersStore.currentPage }">
              <button class="page-link" @click="usersStore.fetchUsers(n)">{{ n }}</button>
            </li>
            <!-- Aquí puedes añadir botones para páginas específicas si lo deseas -->
            <li class="page-item mt-2" :class="{ disabled: !usersStore.nextPage }">
              <button class="page-link" @click="usersStore.fetchUsers(usersStore.currentPage + 1)" :disabled="!usersStore.nextPage">Siguiente</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="card mb-4" v-if="productsStore.products && productsStore.products.length>0">
        <div class="card-header">
        <b>Productos</b>
        <input type="text" class="form-control ms-3 d-inline-block" style="width: auto;" v-model="searchProductQuery" placeholder="Buscar Productos...">
        <router-link :to="{ name: 'ProductForm' }" class="btn btn-success ms-3">Crear Producto</router-link>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Descripcion</th>
              <th>Precio</th>
              <th>Stock</th>
              <th class="text-end">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in productsStore.products" :key="product.id">
              <td>{{ product.id }}</td>
              <td>{{ product.nombre }}</td>
              <td>{{ product.descripcion }}</td>
              <td>{{ product.precio }}</td>
              <td>{{ product.stock }}</td>
              <td class="text-end"> <!-- Alineación a la derecha para los botones -->
                  <router-link :to="{ name: 'ProductDetails', params: { id: product.id } }" class="btn btn-primary me-1">Ver Detalles</router-link>
                  <router-link :to="{ name: 'ProductForm', params: { id: product.id } }" class="btn btn-warning me-1">Editar</router-link>
                  <button class="btn btn-danger" @click="confirmDeleteProduct(product.id)">Eliminar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <nav aria-label="Page navigation">
          <ul class="pagination" v-if="!searchProductQuery">
            <li class="page-item mt-2" :class="{ disabled: !productsStore.prevPage }">
              <button class="page-link" @click="productsStore.fetchProducts(productsStore.currentPage - 1)" :disabled="!productsStore.prevPage">Anterior</button>
            </li>
            <li class="page-item mt-2" v-for="n in productsStore.totalPages" :key="n" :class="{ active: n === productsStore.currentPage }">
              <button class="page-link" @click="productsStore.fetchProducts(n)">{{ n }}</button>
            </li>
            <!-- Aquí puedes añadir botones para páginas específicas si lo deseas -->
            <li class="page-item mt-2" :class="{ disabled: !productsStore.nextPage }">
              <button class="page-link" @click="productsStore.fetchProducts(productsStore.currentPage + 1)" :disabled="!productsStore.nextPage">Siguiente</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="card mb-4" v-if="categoriesStore.categories && categoriesStore.categories.length>0">
        <div class="card-header">
        <b>Categorias</b>
        <input type="text" class="form-control ms-3 d-inline-block" style="width: auto;" v-model="searchCategoryQuery" placeholder="Buscar Categoría...">
        <router-link :to="{ name: 'CategoryForm' }" class="btn btn-success ms-3">Crear Categoría</router-link>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th class="text-end">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="category in categoriesStore.categories" :key="category.id">
              <td>{{ category.id }}</td>
              <td>{{ category.nombre }}</td>
              <td class="text-end"> <!-- Alineación a la derecha para los botones -->
                  <router-link :to="{ name: 'CategoryDetails', params: { id: category.id } }" class="btn btn-primary me-1">Ver Detalles</router-link>
                  <router-link :to="{ name: 'CategoryForm', params: { id: category.id } }" class="btn btn-warning me-1">Editar</router-link>
                  <button class="btn btn-danger" @click="confirmDeleteCategory(category.id)">Eliminar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <nav aria-label="Page navigation">
          <ul class="pagination" v-if="!searchCategoryQuery">
            <li class="page-item mt-2" :class="{ disabled: !categoriesStore.prevPage }">
              <button class="page-link" @click="categoriesStore.fetchCategories(categoriesStore.currentPage - 1)" :disabled="!categoriesStore.prevPage">Anterior</button>
            </li>
            <li class="page-item mt-2" v-for="n in categoriesStore.totalPages" :key="n" :class="{ active: n === categoriesStore.currentPage }">
              <button class="page-link" @click="categoriesStore.fetchCategories(n)">{{ n }}</button>
            </li>
            <!-- Aquí puedes añadir botones para páginas específicas si lo deseas -->
            <li class="page-item mt-2" :class="{ disabled: !categoriesStore.nextPage }">
              <button class="page-link" @click="categoriesStore.fetchCategories(categoriesStore.currentPage + 1)" :disabled="!categoriesStore.nextPage">Siguiente</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <!-- Order Section -->
    <div class="card mb-4" v-if="ordersStore.orders && ordersStore.orders.length>0">
        <div class="card-header">
        <b>Pedidos</b>
        <input type="text" class="form-control ms-3 d-inline-block" style="width: auto;" v-model="searchOrderQuery" placeholder="Buscar Pedido...">
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Usuario</th>
              <th>Estado</th>
              <th>Productos</th>
              <th class="text-end">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in ordersStore.orders" :key="order.id">
              <td>{{ order.id }}</td>
              <td>{{ order.usuario.name }}</td>
              <td>{{ order.estado }}</td>
              <td>{{ order.productos.length }}</td>
              <td class="text-end"> <!-- Alineación a la derecha para los botones -->
                  <router-link :to="{ name: 'OrderDetails', params: { id: order.id } }" class="btn btn-primary me-1">Ver Detalles</router-link>
                  <router-link :to="{ name: 'OrderForm', params: { id: order.id } }" class="btn btn-warning me-1">Editar estado</router-link>
                  <button class="btn btn-danger" @click="confirmDeleteOrder(order.id)">Eliminar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer d-flex justify-content-end">
        <nav aria-label="Page navigation">
          <ul class="pagination" v-if="!searchOrderQuery">
            <li class="page-item mt-2" :class="{ disabled: !ordersStore.prevPage }">
              <button class="page-link" @click="ordersStore.fetchOrders(ordersStore.currentPage - 1)" :disabled="!ordersStore.prevPage">Anterior</button>
            </li>
            <li class="page-item mt-2" v-for="n in ordersStore.totalPages" :key="n" :class="{ active: n === ordersStore.currentPage }">
              <button class="page-link" @click="ordersStore.fetchOrders(n)">{{ n }}</button>
            </li>
            <!-- Aquí puedes añadir botones para páginas específicas si lo deseas -->
            <li class="page-item mt-2" :class="{ disabled: !ordersStore.nextPage }">
              <button class="page-link" @click="ordersStore.fetchOrders(ordersStore.currentPage + 1)" :disabled="!ordersStore.nextPage">Siguiente</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

  </div>
  
</template>

  
<script>
import { useUsersStore } from '@/stores/user';
import { useProductsStore } from '@/stores/product'
import { useCategoriesStore } from '@/stores/category'
import { useOrdersStore } from '@/stores/order'
import { ref, watch } from 'vue';

export default {
  name: 'DashboardComponent',
  setup() {
    const usersStore = useUsersStore();
    const searchUserQuery = ref('');
    usersStore.fetchUsers();
    watch(searchUserQuery, (newQuery) => {
      if (newQuery.trim()) {
        usersStore.filterUsers(newQuery);
      } else {
        usersStore.fetchUsers(usersStore.currentPage);
      }
    });

    const productsStore = useProductsStore();
    const searchProductQuery = ref('');
    productsStore.fetchProducts();
    watch(searchProductQuery, (newQuery) => {
      if(newQuery.trim()){
          productsStore.filterProducts(newQuery);
      }else{
          productsStore.fetchProducts(productsStore.currentPage)
      } 
    })

    const categoriesStore = useCategoriesStore();
    const searchCategoryQuery = ref('')
    categoriesStore.fetchCategories();
    watch(searchCategoryQuery, (newQuery) => {
      if(newQuery.trim()){
        categoriesStore.filterCategories(newQuery);
      }else{
        categoriesStore.fetchCategories(categoriesStore.currentPage)
      } 
    })

    const ordersStore = useOrdersStore();
    const searchOrderQuery = ref('')
    ordersStore.fetchOrders(); 
    watch(searchOrderQuery, (newQuery) => {
      if(newQuery.trim()){
        ordersStore.filterOrders(newQuery);
      }else{
        ordersStore.fetchOrders(ordersStore.currentPage)
      } 
    })
    return { usersStore, searchUserQuery, productsStore, searchProductQuery, categoriesStore, searchCategoryQuery, ordersStore, searchOrderQuery};
  },
  methods: {
    async confirmDeleteCategory(categoryId) {
        if (window.confirm("¿Estás seguro de que deseas eliminar esta categoría?")) {
            try {
                const categoriesStore = useCategoriesStore();
                await categoriesStore.deleteCategory(categoryId);
            } catch (error) {
                alert(error);
            }
        }
    },
    async confirmDeleteProduct(productId) {
        if (window.confirm("¿Estás seguro de que deseas eliminar este producto?")) {
            try {
                const productsStore = useProductsStore();
                await productsStore.deleteProduct(productId);
            } catch (error) {
                alert(error);
            }
        }
    },
    async confirmDeleteUser(userId) {
        if (window.confirm("¿Estás seguro de que deseas eliminar este usuario?")) {
            try {
                const usersStore = useUsersStore();
                await usersStore.deleteUser(userId);
            } catch (error) {
                alert(error);
            }
        }
    },
    async confirmDeleteOrder(orderId) {
        if (window.confirm("¿Estás seguro de que deseas eliminar este pedido?")) {
            try {
                const ordersStore = useOrdersStore();
                const admin = true;
                await ordersStore.deleteOrder(orderId, admin);
            } catch (error) {
                alert(error);
            }
        }
    }
}

};
</script>


  
<style scoped>

</style>
  
  
  