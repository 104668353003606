<template>
    <div class="store-component container my-5" v-if="hasProducts">
      <h1 class="text-center mb-4">Nuestros productos</h1>
      <div class="row mb-3">
      <div class="col-md-8">
        <input type="text" class="form-control" v-model="searchProductQuery" placeholder="Buscar Productos...">
      </div>
      <div class="col-md-4">
        <select class="form-select" v-model="selectedCategory">
        <option disabled value="">Seleccione una categoría</option>
        <option value="all">Todas las categorías</option>
        <option v-for="category in categoriesStore.allCategories" :key="category.id" :value="category.id">{{ category.nombre }}</option>
        </select>
      </div>
    </div>
      <div class="row g-4">
        <div class="col-md-4" v-for="product in productsStore.products" :key="product.id">
          <div class="card h-100 shadow">
            <img :src="getImageSrc(product.image)" class="card-img-top" alt="Product" style="height: 160px; object-fit: cover;">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">{{ product.nombre }}</h5>
              <p class="card-text flex-grow-1">{{ product.descripcion }}</p>
              <div class="d-flex justify-content-between align-items-cente mb-3">
                    <router-link :to="{ name: 'CategoryDetails', params: { id: product.categoria.id } }" class="btn btn-outline-primary btn-sm">
                    {{ product.categoria.nombre }}
                    </router-link>
                    <p class="card-text text-end fw-bold">{{ product.precio }}€</p>
              </div>
              <router-link :to="{ name: 'ProductDetails', params: { id: product.id } }" class="btn btn-primary mt-2">Ver Detalles</router-link>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="card-footer d-flex justify-content-center">
        <nav aria-label="Page navigation">
          <ul class="pagination" v-if="!searchProductQuery">
            <li class="page-item mt-2" :class="{ disabled: !productsStore.prevPage }">
              <button class="page-link" @click="productsStore.fetchProducts(productsStore.currentPage - 1)" :disabled="!productsStore.prevPage">Anterior</button>
            </li>
            <li class="page-item mt-2" v-for="n in productsStore.totalPages" :key="n" :class="{ active: n === productsStore.currentPage }">
              <button class="page-link" @click="productsStore.fetchProducts(n)">{{ n }}</button>
            </li>
            <li class="page-item mt-2" :class="{ disabled: !productsStore.nextPage }">
              <button class="page-link" @click="productsStore.fetchProducts(productsStore.currentPage + 1)" :disabled="!productsStore.nextPage">Siguiente</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container my-5" v-else>
      <div class="d-flex justify-content-center">
        <h3 class="me-3">Cargando productos...</h3>
        <div class="spinner-border text-primary" role="status">
          <span></span>
        </div>
      </div>
    </div>
  </template>
  
<script>
import { useProductsStore } from '@/stores/product'
import { useCategoriesStore } from '@/stores/category'
import { ref, computed, watch } from 'vue';
import { getImageSrc } from '@/library';
  export default {
    name: 'StoreComponent',
        setup() {
            const productsStore = useProductsStore();
            const categoriesStore = useCategoriesStore();
            const searchProductQuery = ref('');
            const selectedCategory = ref('');
            productsStore.fetchProducts();
            categoriesStore.fetchAllCategories();
            const hasProducts = computed(() => productsStore.products.length > 0);
            watch([searchProductQuery, selectedCategory], ([newQuery, newCategory]) => {
            if (newCategory && newCategory !== 'all') {
                productsStore.fetchProductsByCategory(newCategory);
            } else if (newQuery.trim()) {
              console.log(newQuery);
                productsStore.filterProducts(newQuery);
            } else {
                productsStore.fetchProducts(productsStore.currentPage);
            }
        });
        return { 
        productsStore, 
        searchProductQuery, 
        getImageSrc, 
        categoriesStore,
        selectedCategory,
        hasProducts
        };
    },
  };
  </script>