<template>
    <div class="container mt-5">
        <div class="card">
        <div class="card-header">
            Detalles del Producto
        </div>
        <div class="d-flex" v-if="producto">
                <ul class="list-group list-group-flush flex-grow-1">
                <li class="list-group-item">ID: {{ producto.id }}</li>
                <li class="list-group-item">Nombre: {{ producto.nombre }}</li>
                <li class="list-group-item">Descripción: {{ producto.descripcion }}</li>
                <li class="list-group-item">Precio: {{ producto.precio }}</li>
                <li class="list-group-item">Stock: {{ producto.stock }}</li>
                <li class="list-group-item">Categoría:
                  <router-link :to="{ name: 'CategoryDetails', params: { id: producto.categoria.id } }" class="btn btn-primary btn-sm">{{ producto.categoria.nombre }}</router-link>
                </li>
                </ul>
                <div class="image-container ms-3 card-header" v-if="producto.image">
                    <div class="card">
                        <div class="user-image" :style="{ backgroundImage: 'url(' + producto.image + ')' }">
                    </div>
                </div>       
            </div>
          </div>
        </div>
        <div v-if="auth.isAuthenticated && hasOrders" class="container mt-4">
          <div v-if="hasOrders" class="card">
            <div class="card-header">
              Tu pedidos
            </div>
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">ID del Pedido</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col">Agregar o eliminar de pedido</th>
                    <th scope="col">Anotaciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(orderInfo, index) in ordersWithProduct" :key="index">
                    <td>{{ orderInfo.order.id }}</td>
                    <td>{{ orderInfo.order.estado }}</td>
                    <td>{{ orderInfo.quantity }}</td>
                    <td>
                      <button class="btn btn-outline-secondary custom-btn-large me-3"
                              :class="{ 'disabled-opacity': !['pendiente', 'nuevo'].includes(orderInfo.order.estado) }"
                              @click="['pendiente', 'nuevo'].includes(orderInfo.order.estado) && decreaseQuantity(orderInfo.order.id, producto.id, index)"
                              :disabled="!['pendiente', 'nuevo'].includes(orderInfo.order.estado)">-</button>

                      <button class="btn btn-outline-secondary custom-btn-large"
                              :class="{ 'disabled-opacity': !['pendiente', 'nuevo'].includes(orderInfo.order.estado) }"
                              @click="['pendiente', 'nuevo'].includes(orderInfo.order.estado) && increaseQuantity(orderInfo.order.id, producto.id, index, producto.stock)"
                              :disabled="!['pendiente', 'nuevo'].includes(orderInfo.order.estado)">+</button>
                    </td>
                    <td class="text-primary" v-if="orderInfo.order.estado === 'completado'">*No se pueden modificar pedidos completados</td>
                    <td class="text-primary" v-if="orderInfo.order.estado === 'pendiente'">*El pedido se encuentra en estado pendiente</td>
                    <td class="text-primary" v-if="orderInfo.order.estado === 'nuevo'">*Prepara tu pedido</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else class="no-orders-container">
          <div class="d-flex justify-content-end">
            <button class="btn btn-success mt-3" @click="createOrder">Crear Pedido</button>
          </div>
        </div>
      </div>
</template>
  
  <script>
  import { useProductsStore } from '@/stores/product';
  import { useAuthStore } from '@/stores/auth';
  import { useOrdersStore } from '@/stores/order';
  import { onMounted, computed, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { getImageSrc } from '@/library';
  
  export default {
    name: 'ProductDetails',
    props: {
      id: [Number, String]
    },
    setup(props) {
      const producto = ref(null);
      const auth = useAuthStore();
      const store = useProductsStore();
      const order = useOrdersStore();
      const router = useRouter();
      const ordersWithProduct = ref([]);
      const hasOrders = computed(() => ordersWithProduct.value.length > 0);

      const increaseQuantity = async (pedidoId, productId, index, stock) => {
        if (ordersWithProduct.value[index].quantity >= stock) {
          return
        }
        let newQuantity = ordersWithProduct.value[index].quantity + 1;
        await updateOrder(pedidoId, productId, newQuantity, index);
      };

      const decreaseQuantity = async (pedidoId, productId, index) => {
        let currentQuantity = ordersWithProduct.value[index].quantity;
        let newQuantity = currentQuantity - 1;

        if (newQuantity >= 1) {
          await updateOrder(pedidoId, productId, newQuantity, index);
        }else{
          ordersWithProduct.value[index].quantity = 0;
          await order.removeProductsFromOrder(pedidoId, [productId]);
        }
      };

      const updateOrder = async (pedidoId, productId, newQuantity, index) => {
        try {
          await order.removeProductsFromOrder(pedidoId, [productId]);
          if (newQuantity > 0) {
            await order.addProductsToOrder(pedidoId, [{ productId: productId, cantidad: newQuantity }]);
          }
          ordersWithProduct.value[index].quantity = newQuantity;
        } catch (error) {
          console.error("Error updating order quantity:", error);
        }
      };

      const getProductQuantity = (productos, productId) => {
        const product = productos.find(p => p.id === productId);
        return product ? product.ProductoPedido.cantidad : 0;
      };

      const createOrder = async () => {
        if (!auth.isAuthenticated) {
          router.push('/login');
          return;
        }
        try {
          const newOrder = {
            fechaHora: new Date(),
            estado: 'nuevo',
            userId: auth.user.id,
          };

          const response = await order.createOrder(newOrder);
          if (response && response.status === 201) {
            await order.fetchPedidosAuth();
            ordersWithProduct.value = order.orders.map(o => ({
              order: o,
              isInOrder: o.productos.some(p => p.id === producto.value.id),
              quantity: 0,
            }));
          }
        } catch (error) {
          console.error("Error creating order:", error);
        }
      };

      onMounted(async () => {
        const fetchedProduct = await store.fetchProductById(props.id);
        if (fetchedProduct) {
          producto.value = fetchedProduct;
          producto.value.image = getImageSrc(producto.value.image)
        } else {
          router.push('/error-404');
        }
        if (auth.isAuthenticated) {
        await order.fetchPedidosAuth();
        order.orders.forEach(o => {
          const isProductInOrder = o.productos.some(p => p.id === producto.value.id);
          const initialQuantity = isProductInOrder ? getProductQuantity(o.productos, producto.value.id) : 0;
          ordersWithProduct.value.push({
            order: o,
            isInOrder: isProductInOrder,
            quantity: initialQuantity,
          });
        });    
      }
      });
      return { producto, auth, order, ordersWithProduct, hasOrders, createOrder, getProductQuantity, increaseQuantity, decreaseQuantity };
    }
  };
  </script>

  <style scoped>
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .user-image {
    width: 150px; /* Ancho fijo */
    height: 150px; /* Altura fija */
    background-size: cover; /* Ajusta la imagen para cubrir el contenedor */
    background-position: center; /* Centra la imagen en el contenedor */
  }
  
.image-container {
    align-self: center; /* Centra el contenedor de la imagen verticalmente */
}
  .disabled-opacity {
  opacity: 0.5; /* Make the button look faded */
  cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
}
.custom-btn-large {
  padding: 10px 20px; /* Increase padding */
  font-size: 1.2em; /* Larger text */
  min-width: 100px; /* Minimum width */
  min-height: 50px; /* Minimum height */
}
</style>