<template>
    <div class="container mt-5" v-if="user">
      <div class="card">
        <div class="card-header">
          Datos del Usuario
          <router-link :to="{ name: 'UserForm' }" class="btn btn-primary btn-sm">Editar Usuario</router-link>
        </div>
        <UserViewDetails :userDetails="user" :imageSrc="imageSrc"/>
      </div>
    </div>
    <div v-else>
      <p class="text-center mt-4">El usuario no está autenticado.</p>
    </div>
    <UserOrders :id="id" />
</template>
  
  
<script>
import { useUsersStore } from '@/stores/user';
import { onMounted, ref, computed } from 'vue';
import UserOrders from '@/components/partials/UserOrders.vue';
import UserViewDetails from '@/components/partials/UserViewDetails.vue';
import { getImageSrc } from '@/library';

export default {
  name: 'UserDetails',
  props: {
    id: [Number, String]
  },
  components: {
    UserOrders,
    UserViewDetails
  },
  setup(props) {
    const userStore = useUsersStore();
    const user = ref(null);

    const loadUser = async () => {
      user.value = await userStore.fetchUserById(props.id);
    };

    const imageSrc = computed(() => {
      if (user.value && user.value.image) {
        return getImageSrc(user.value.image);
      }
      return null;
    });

    onMounted(loadUser);
    return { user, imageSrc};
  }
};
</script>

<style scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
}

.user-image {
  width: 150px; /* Ancho fijo */
  height: 150px; /* Altura fija */
  background-size: cover; /* Ajusta la imagen para cubrir el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
}

.image-container {
  align-self: center; /* Centra el contenedor de la imagen verticalmente */
}
</style>
  