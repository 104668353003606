<template>
  <div class="container mt-5" v-if="store.isAuthenticated">
    <div class="card">
      <div class="card-header">
        Datos del Usuario
        <router-link :to="{ name: 'UserForm' }" class="btn btn-primary">Editar Perfil</router-link>
      </div>
      <UserViewDetails :userDetails="store.user" :imageSrc="imageSrc"/>
    </div>
  </div>
  <div v-else>
    <p class="text-center mt-4">El usuario no está autenticado.</p>
  </div>
  <UserOrders :id="store.user.id" />
</template>



<script>
import { useAuthStore } from '@/stores/auth';
import { computed } from 'vue';
import { getImageSrc } from '@/library';
import UserOrders from '@/components/partials/UserOrders.vue';
import UserViewDetails from '@/components/partials/UserViewDetails.vue';

export default {
  name: 'AccountComponent',
  components: {
    UserOrders,
    UserViewDetails
  },
  setup() {
    const store = useAuthStore();

    const imageSrc = computed(() => {
      if (store.user && store.user.image) {
        return getImageSrc(store.user.image);
      }
      return null;
    });

    return { store, imageSrc };
  }
};
</script>

<style scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
}

.user-image {
  width: 150px; /* Ancho fijo */
  height: 150px; /* Altura fija */
  background-size: cover; /* Ajusta la imagen para cubrir el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
}

.image-container {
  align-self: center; /* Centra el contenedor de la imagen verticalmente */
}
</style>

