<template>
    <div class="error-page">
      <h1>Error 401: Acceso no autorizado</h1>
      <p>No tienes autorización para acceder a esta página.</p>
      <router-link to="/">Volver al inicio</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Error403Component',
  }
  </script>
  
  <style>
  .error-page {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  