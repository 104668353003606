export const getImageSrc = (imageData) => {
    if (imageData) {
      const buffer = imageData.data;
      let binary = '';
      buffer.forEach((byte) => {
        binary += String.fromCharCode(byte);
      });
      return 'data:image/jpeg;base64,' + window.btoa(binary);
    }
    return null;
  };
  