<template>
    <div class="container mt-5 mb-5" v-if="ordersStore.orders.length > 0">
      <div class="card">
      <div class="card-header">
        <b>Pedidos del Usuario</b>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Estado</th>
              <th>Productos</th>
              <th class="text-end">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pedido in ordersStore.orders" :key="pedido.id">
              <td>{{ pedido.id }}</td>
              <td>{{ pedido.estado }}</td>
              <td>{{ pedido.productos.length }}</td>
              <td class="text-end">
                <router-link :to="{ name: 'OrderDetails', params: { id: pedido.id } }" class="btn btn-primary me-1">Ver Detalles</router-link>
                <button class="btn btn-danger" @click="handleDeleteOrder(pedido.id)">Eliminar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
        <div class="card-footer d-flex justify-content-end" v-if="user">
          <nav aria-label="Page navigation" class="mt-3">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: !ordersStore.prevPage }">
                <button class="page-link" @click="cambiarPagina(ordersStore.currentPage - 1)" :disabled="!ordersStore.prevPage">Anterior</button>
              </li>
              <li class="page-item" v-for="n in ordersStore.totalPages" :key="n" :class="{ active: n === ordersStore.currentPage }">
                <button class="page-link" @click="cambiarPagina(n)">{{ n }}</button>
              </li>
              <li class="page-item" :class="{ disabled: !ordersStore.nextPage }">
                <button class="page-link" @click="cambiarPagina(ordersStore.currentPage + 1)" :disabled="!ordersStore.nextPage">Siguiente</button>
              </li>
            </ul>
          </nav>
        </div>
    </div>
  </div>
</template>
  
  
<script>
import { useUsersStore } from '@/stores/user';
import { useOrdersStore } from '@/stores/order';
import { useAuthStore } from '@/stores/auth';
import { onMounted, ref, computed } from 'vue';
import { getImageSrc } from '@/library';

export default {
  name: 'UserDetails',
  props: {
    id: [Number, String]
  },
  setup(props) {
    const userStore = useUsersStore();
    const ordersStore = useOrdersStore();
    const authStore = useAuthStore();
    const user = ref(null);

    const loadUser = async () => {
      if (props.id) {
        user.value = await userStore.fetchUserById(props.id);
        if(authStore.user.admin){
          await ordersStore.fetchPedidosByUserId(props.id);
        }else{
          await ordersStore.fetchPedidosAuth()
        }
      }
    };

    const handleDeleteOrder = async (pedidoId) => {
      if (confirm('¿Estás seguro de que deseas eliminar este pedido?')) {
        try {
          await ordersStore.deleteOrder(pedidoId);
          if(authStore.user.admin){
            await ordersStore.fetchPedidosByUserId(props.id);
          }else{
            ordersStore.orders = [];
            await ordersStore.fetchPedidosAuth()
          }
        } catch (error) {
          console.error("Error deleting order:", error);
        }
      }
    };

    const imageSrc = computed(() => {
      if (user.value && user.value.image) {
        return getImageSrc(user.value.image);
      }
      return null;
    });

    const cambiarPagina = (page) => {
      ordersStore.fetchPedidosByUserId(props.id, page);
    };

    onMounted(loadUser);
    return { user, imageSrc, cambiarPagina, ordersStore, handleDeleteOrder};
  }
};
</script>

<style scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
}

.user-image {
  width: 150px; /* Ancho fijo */
  height: 150px; /* Altura fija */
  background-size: cover; /* Ajusta la imagen para cubrir el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
}

.image-container {
  align-self: center; /* Centra el contenedor de la imagen verticalmente */
}
</style>
  