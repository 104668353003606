<template>
  <div class="container-fluid px-0">
    <!-- Carrusel -->
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/landing2.jpeg" class="d-block w-100" alt="Landing 1">
        </div>
        <div class="carousel-item">
          <img src="../assets/landing1.jpeg" class="d-block w-100" alt="Landing 2">
        </div>
        <div class="carousel-item">
          <img src="../assets/landing3.jpeg" class="d-block w-100" alt="Landing 3">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- Lista de productos o categorías -->
    <div class="row g-2 mt-3">
      <h1 class="m-3">En tendencia</h1>
        <div class="col-md-4" v-for="product in products" :key="product.id">
          <div class="card h-100 m-3">
            <img :src="getImageSrc(product.image)" class="card-img-top" alt="Product" style="height: 250px; object-fit: cover;">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title">{{ product.nombre }}</h5>
              <p class="card-text flex-grow-1">{{ product.descripcion }}</p>
              <div class="d-flex justify-content-between align-items-cente mb-3">
                    <router-link :to="{ name: 'CategoryDetails', params: { id: product.categoria.id } }" class="btn btn-outline-primary btn-sm">
                    {{ product.categoria.nombre }}
                    </router-link>
                    <p class="card-text text-end fw-bold">{{ product.precio }}€</p>
              </div>
              <router-link :to="{ name: 'ProductDetails', params: { id: product.id } }" class="btn btn-primary mt-2">Ver Detalles</router-link>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getImageSrc } from '@/library';
export default {

  name: 'HomeView',
  data() {
    return {
      products: []
    };
  },
  methods: {
    getImageSrc
  },
  async mounted() {
    try {
      const response = await axios.get('https://silkroad-server.carlosrobles.es/api/v1/productos?page=1&limit=3');
      this.products = response.data.items;
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }
}
</script>

<style scoped>
.carousel-item img {
  height: 400px;
  object-fit: cover;
}

.card-img-top {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

</style>
