import HomeView from '@/views/HomeView.vue';
import AccountComponent from '@/components/AccountComponent.vue';
import StoreView from '@/components/StoreComponent.vue';
import UserForm from '@/components/forms/UserForm.vue';
import DashboardComponent from '@/components/DashboardComponent.vue';
import ProductDetails from '@/components/details/ProductDetails.vue'
import CategoryDetails from '@/components/details/CategoryDetails.vue'
import UserDetails from '@/components/details/UserDetails.vue'
import OrderDetails from '@/components/details/OrderDetails.vue'
import CategoryForm from '@/components/forms/CategoryForm.vue'
import ProductForm from '@/components/forms/ProductForm.vue'
import OrderForm from '@/components/forms/OrderForm.vue'

export const mainRoutes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/store',
    name: 'StoreView',
    component: StoreView,
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountComponent,
    meta: { requiresAuth: true }
  },
  {
    path: '/user-form',
    name: 'UserForm',
    component: UserForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/order-form/:id',
    name: 'OrderForm',
    component: OrderForm,
    props: true,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/user-form-admin/:id',
    name: 'UserFormAdmin',
    component: UserForm,
    props: true,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/dashboard',
    name: 'DashboardComponent',
    component: DashboardComponent,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/product-view/:id',
    name: 'ProductDetails',
    component: ProductDetails,
    props: true
  },
  {
    path: '/categorias-view/:id',
    name: 'CategoryDetails',
    component: CategoryDetails,
    props: true
  },
  {
    path: '/user-admin/:id',
    name: 'UserDetails',
    component: UserDetails,
    props: true,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/order-view/:id',
    name: 'OrderDetails',
    component: OrderDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/form-category/:id?',
    name: 'CategoryForm',
    component: CategoryForm,
    props: true,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/form-product/:id?',
    name: 'ProductForm',
    component: ProductForm,
    props: true,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
];
