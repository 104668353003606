<template>
    <div class="d-flex">
      <ul class="list-group list-group-flush flex-grow-1">
        <li class="list-group-item">ID: {{ userDetails.id }}</li>
        <li class="list-group-item">Nombre: {{ userDetails.name }}</li>
        <li class="list-group-item">Nombre de Usuario: {{ userDetails.username }}</li>
        <li class="list-group-item">Email: {{ userDetails.email }}</li>
        <li class="list-group-item">Dirección: {{ userDetails.address }}</li>
        <li class="list-group-item">Admin: {{ userDetails.admin ? 'Sí' : 'No' }}</li>
      </ul>
      <div class="image-container ms-3 card-header" v-if="imageSrc">
        <div class="card">
          <div class="user-image" :style="{ backgroundImage: 'url(' + imageSrc + ')' }"></div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'UserViewDetails',
    props: {
      userDetails: {
        type: Object,
        required: true
      },
      imageSrc: String
    }
  };
  </script>
  
  <style scoped>
  .user-image {
    width: 150px; /* Ancho fijo */
    height: 150px; /* Altura fija */
    background-size: cover; /* Ajusta la imagen para cubrir el contenedor */
    background-position: center; /* Centra la imagen en el contenedor */
  }
  .image-container {
    align-self: center; /* Centra el contenedor de la imagen verticalmente */
  }
  </style>
  