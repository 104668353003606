<template>
    <div class="container mx-auto mt-5" style="width:30%">
      <h3 v-if="$route.path === '/register'">Registrarse</h3>
      <h3 v-else>Iniciar sesión</h3>
      <form @submit.prevent="$route.path === '/register' ? register() : login()" :class="{ shake: hasError }">
        <div class="form-group mt-4">
          <label for="username">Nombre de Usuario</label>
          <input v-model="username" @input="isTyping = true" type="text" class="form-control" id="username" required/>
        </div>
  
        <!-- Campo de correo electrónico solo para registro -->
        <div class="form-group mt-1" v-if="$route.path === '/register'">
          <label for="email">Correo electrónico</label>
          <input v-model="email" @input="isTyping = true" type="email" class="form-control" id="email" required/>
        </div>
  
        <!-- Campos adicionales para registro -->
        <div v-if="$route.path === '/register'">
          <div class="form-group mt-1">
            <label for="name">Nombre</label>
            <input v-model="name" type="text" class="form-control" id="name" />
          </div>
          <div class="form-group mt-1">
            <label for="address">Dirección</label>
            <input v-model="address" type="text" class="form-control" id="address" />
          </div>
        </div>
  
        <div class="form-group mt-1">
          <label for="password">Contraseña</label>
          <input v-model="password" @input="isTyping = true" type="password" class="form-control" id="password" />
        </div>
  
        <button type="submit" class="btn btn-primary mt-3">
          {{ $route.path === '/register' ? 'Registrar' : 'Iniciar Sesión' }}
        </button>
      </form>
      <transition name="fade">
        <div v-if="isTyping && validationMessage" :class="{'text-danger': true, 'mt-3': true, shake: showError}">
          {{ validationMessage }}
        </div>
      </transition>
      <transition name="fade">
        <div v-if="message" :class="{'text-success': isSuccess, 'text-danger': !isSuccess, 'mt-3': true}">
          {{ message }}
        </div>
      </transition>
    </div>
  </template>
  
  
<script>
import { useAuthStore } from '@/stores/auth';
export default {
  name: 'AuthForm',
    data() {
      return {
      username: '',
      email: '',
      password: '',
      message: '',
      isTyping: false,
      hasError: false,
      showError: false,
      isSuccess: false
    };
    },
    computed: {
      validationMessage() {
        if (this.username === '' || this.password === '' || (this.$route.path === '/register' && this.email === '')) {
          return 'Todos los campos son obligatorios';
        }
        if (this.password.length < 4) {
          return 'La contraseña debe tener al menos 4 caracteres';
        }
        if (this.email !== '' && !this.email.includes('@')) {
          return 'El correo electrónico debe ser válido';
        }
        return '';
      }
    },
    watch: {
      validationMessage(newValue) {
        if(newValue !== '') {
          this.showError = true;
          setTimeout(() => this.showError = false, 830);
        }
      }
    },
    methods: {
        triggerShake() {
          this.hasError = true;
          setTimeout(() => {
            this.hasError = false;
          }, 330);
        },
        async register() {
            const authStore = useAuthStore();
            await authStore.register(this.username, this.email, this.password, this.name, this.address);
            this.message = authStore.message;
            this.isSuccess = authStore.isSuccess;
            this.validationMessage = this.message 
            if(!this.isSuccess){
              this.triggerShake();
            }
        },
        async login() {
            const authStore = useAuthStore();
            await authStore.login(this.username, this.password);
            this.message = authStore.message;
            if(authStore.isSuccess){
                this.$router.push('/');
            }else{
              this.triggerShake();
            }
        }
    },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
  20%, 40%, 60%, 80% { transform: translateX(10px); }
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

</style>
  
