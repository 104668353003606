import AuthForm from '@/components/forms/AuthForm.vue';

export const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: AuthForm,
  },
  {
    path: '/register',
    name: 'Register',
    component: AuthForm,
  },
];
