<template>
    <div class="container mt-4">
      <h2 class="mt-5 mb-4">{{ this.id ? 'Editar Usuario' : 'Edita tu perfil' }}</h2>
      <form @submit.prevent="updateUser">
        <div class="mb-3">
          <label for="name" class="form-label">Nombre</label>
          <input type="text" class="form-control" id="name" v-model="formData.name">
        </div>
        <div class="mb-3">
          <label for="username" class="form-label">Nombre de Usuario</label>
          <input type="text" class="form-control" id="username" v-model="formData.username">
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control" id="email" v-model="formData.email">
        </div>
        <div class="mb-3">
          <label for="address" class="form-label">Dirección</label>
          <input type="text" class="form-control" id="address" v-model="formData.address">
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Contraseña</label>
          <input type="password" class="form-control" id="password" v-model="formData.password">
        </div>
        <div class="image-upload-row mt-5">
            <div class="image-container" v-if="imagePreview">
                <div class="card">
                    <div class="user-image" :style="{ backgroundImage: 'url(' + imagePreview + ')' }"></div>
                </div>
            </div>
            <div class="mb-3">
                <label for="image" class="form-label">Imagen</label>
                <input type="file" class="form-control" id="image" @change="handleFileUpload">
            </div>

        </div>
        <button type="submit" class="btn btn-primary button-container">Actualizar</button>
        <br><br>
        <div v-if="message" :class="{'text-success': isSuccess, 'text-danger': !isSuccess, 'mt-3': true}">
            {{ message }}
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { useAuthStore } from '@/stores/auth';
  import { useUsersStore } from '@/stores/user';
  import { getImageSrc } from '@/library';

  export default {
    name: 'UserEditForm',
    props: {
        id: {
            type: [Number, String],
            default: null
        }
    },
    data() {
      return {
        formData: {
          name: '',
          username: '',
          email: '',
          address: '',
          password: '',
          imageFile: null,
        },
        imagePreview: null,
        message: '',
        isSuccess: false
      };
    },
    setup() {
        const authStore = useAuthStore();
        const userStore = useUsersStore();
        return { authStore, userStore };
    },
    created() {
      this.loadUserData();
    },
    methods: {
      handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.formData.image = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
      loadUserData() {
        if (this.id) {
          this.userStore.fetchUserById(this.id).then((response) => {
            console.log(response);
            this.formData.name = response.name;
            this.formData.username = response.username;
            this.formData.email = response.email;
            this.formData.address = response.address;
            this.imagePreview = getImageSrc(response.image) ?? null;
          });
        } else {
          this.formData.name = this.authStore.user.name;
          this.formData.username = this.authStore.user.username;
          this.formData.email = this.authStore.user.email;
          this.formData.address = this.authStore.user.address;
          this.imagePreview = getImageSrc(this.authStore.user.image) ?? null;
        }
      },
      async updateUser() {
        if (!this.validateUser()) {
            return;
        }

        const userData = {
            name: this.formData.name,
            username: this.formData.username,
            email: this.formData.email,
            address: this.formData.address,
            ...(this.formData.password && { password: this.formData.password }),
        };

        if (this.formData.image && this.formData.image instanceof File) {
          userData.image = this.formData.image;
        }

        try {
            if (this.id) {
                await this.userStore.updateUser(this.id, userData);
            } else {
                await this.authStore.updateUser(userData);
            }
            this.message = 'Usuario actualizado con éxito.';
            this.isSuccess = true;
        } catch (error) {
            this.message = error.response?.data?.error || 'Ocurrió un error al actualizar el usuario.';
            this.isSuccess = false;
        }
      },
      validateUser() {
        const { name, username, email, address } = this.formData;
          if (!name.trim()) {
              this.message += 'El campo nombre es obligatorio. ';
              this.isSuccess = false;
              return false;
          }
          if (!username.trim()) {
              this.message += 'El campo nombre de usuario es obligatorio. ';
              this.isSuccess = false;
              return false;
          } else if (username.length < 4 || username.length > 20) {
              this.message += 'El nombre de usuario debe tener entre 4 y 20 caracteres. ';
              this.isSuccess = false;
              return false;
          }
          if (!email.trim()) {
              this.message += 'El campo de correo electrónico es obligatorio. ';
              this.isSuccess = false;
              return false;
          } else if (!/^\S+@\S+\.\S+$/.test(email)) {
              this.message += 'El correo electrónico no tiene un formato válido. ';
              this.isSuccess = false;
              return false;
          }
          if (!address.trim()) {
              this.message += 'El campo dirección es obligatorio. ';
              this.isSuccess = false;
              return false;
          } else if (address.length < 5) {
              this.message += 'La dirección debe contener al menos 5 caracteres. ';
              this.isSuccess = false;
              return false;
          }
          return true;
        }
    }
  }
  </script>
  
  <style scoped>
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .user-image {
    width: 150px;
    height: 150px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
  }
  
  .image-upload-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .image-container {
    margin-right: 20px;
  }
  
  .button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
  }
  </style>
  