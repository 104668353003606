<template>
  <div id="app">
    <AppNavbar /><br><br>
    <router-view></router-view>
  </div>
</template>

<script>

import AppNavbar from './components/partials/AppNavbar.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
  },
};
</script>