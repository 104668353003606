import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    user: null,
    message: '',
    isSuccess: false,
  }),
  actions: {
    checkAuth() {
      const token = localStorage.getItem('authToken');
      this.user = JSON.parse(localStorage.getItem('user'));
      this.isAuthenticated = !!token;
    },
    getters: {
      isAdmin: (state) => {
        return state.user && state.user.admin;
      }
    },
    async register(username, email, password, name, address) {
      try {
        const response = await axios.post('https://silkroad-server.carlosrobles.es/api/v1/register', {
          username, email, password, name, address
        });
        if (response.status === 201) {
          this.message = 'Registro exitoso';
          this.isSuccess = true;
        }
      } catch (error) {
        if(error.response == undefined) {
          this.message = 'No se ha podido conectar con el servidor';
        }else{
          this.message = 'Error en el registro: ' + error.response.data.error;
        }
        this.isSuccess = false;
      }
    },
    async login(username, password) {
      try {
        const response = await axios.post('https://silkroad-server.carlosrobles.es/api/v1/login', {
          username, password
        });
        if (response.data && response.data.token) {
          this.isAuthenticated = true;
          this.user = response.data.user;
          this.message = 'Inicio de sesión exitoso';
          localStorage.setItem('authToken', response.data.token);
          localStorage.setItem('user', JSON.stringify(response.data.user));
          this.isSuccess = true;
        }
      } catch (error) {
        if(error.response == undefined) {
          this.message = 'No se ha podido conectar con el servidor';
        }else{
          this.message = 'Error en el inicio de sesión: ' + error.response.data.error;
        }
        this.isSuccess = false;
      }
    },
    async updateUser(updatedUserData) {
      try {
        const response = await axios.put('https://silkroad-server.carlosrobles.es/api/v1/usuarios/' + this.user.id, updatedUserData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 200) {
          this.user = response.data.user;
          localStorage.setItem('user', JSON.stringify(response.data.user));
          this.message = 'Usuario actualizado con éxito';
          this.isSuccess = true;
        }
      } catch (error) {
        this.message = error.response.data.error;
        this.isSuccess = false;
      }
    },
    logout() {
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
      this.isAuthenticated = false;
      this.user = null;
    }
  },
});
