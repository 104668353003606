<template>
  <div class="container mt-5" v-if="categoria">
    <!-- Sección de Detalles de la Categoría -->
    <div class="card mb-4"> <!-- Agregado un margen inferior -->
      <div class="card-header">
        Detalles de la Categoría
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">ID: {{ categoria.id }}</li>
          <li class="list-group-item">Nombre: {{ categoria.nombre }}</li>
        </ul>
      </div>
    </div>

    <!-- Sección de Tabla de Productos -->
    <div class="card" v-if="productosFiltrados">
      <div class="card-header">
        Productos de la Categoría
        <input type="text" class="form-control ms-3 d-inline-block" style="width: auto;" v-model="searchProductQuery" placeholder="Buscar Productos...">
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Descripción</th>
              <th>Precio</th>
              <th>Stock</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="producto in productosFiltrados" :key="producto.id">
              <td>{{ producto.id }}</td>
              <td>{{ producto.nombre }}</td>
              <td>{{ producto.descripcion }}</td>
              <td>{{ producto.precio }}€</td>
              <td>{{ producto.stock }}</td>
              <td>
                <router-link :to="{ name: 'ProductDetails', params: { id: producto.id } }" class="btn btn-primary">Ver Detalles</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Controles de paginación -->
      <div class="card-footer d-flex justify-content-end" v-if="!searchProductQuery.trim()">
        <nav aria-label="Page navigation">
          <ul class="pagination">
            <li class="page-item mt-2" :class="{ disabled: !categoryDetails.prevPage }">
              <button class="page-link" @click="cambiarPagina(categoryDetails.currentPage - 1)" :disabled="!categoryDetails.prevPage">Anterior</button>
            </li>
            <li class="page-item mt-2" v-for="n in categoryDetails.totalPages" :key="n" :class="{ active: n === categoryDetails.currentPage }">
              <button class="page-link" @click="cambiarPagina(n)">{{ n }}</button>
            </li>
            <li class="page-item mt-2" :class="{ disabled: !categoryDetails.nextPage }">
              <button class="page-link" @click="cambiarPagina(categoryDetails.currentPage + 1)" :disabled="!categoryDetails.nextPage">Siguiente</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>


<script>
import { useCategoriesStore } from '@/stores/category';
import { onMounted, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'CategoryDetails',
  props: {
    id: [Number, String]
  },
  setup(props) {
    const categoriesStore = useCategoriesStore();
    const searchProductQuery = ref('');
    const categoria = ref(null);
    const router = useRouter();

    onMounted(() => {
      cargarCategoria(categoriesStore.categoryDetails.currentPage);
    });

    watch(searchProductQuery, async (newQuery) => {
      if (newQuery.trim()) {
        await categoriesStore.filterProduct(props.id, newQuery);
      } else {
        cargarCategoria(categoriesStore.categoryDetails.currentPage);
      }
    });

    const cargarCategoria = async (page = 1, limit = 5) => {
      const fetchedCategory = await categoriesStore.fetchCategoryById(props.id, page, limit);
      if (fetchedCategory) {
        categoria.value = fetchedCategory;
      } else {
        router.push('/error-404');
      }
    };

    const productosFiltrados = computed(() => {
      return categoriesStore.categoryDetails.productos;
    });

    const cambiarPagina = (page) => {
      cargarCategoria(page);
    };

    return { categoria, categoryDetails: categoriesStore.categoryDetails, searchProductQuery, cambiarPagina, productosFiltrados};
  }
};
</script>


<style scoped>
  /* Tus estilos aquí */
</style>
