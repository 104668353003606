<template>
  <div class="container mt-5">
    <div v-if="ordersStore.currentPedido">
      <div class="card mb-4">
        <div class="card-header">Detalles del Pedido con ID:{{ ordersStore.currentPedido.id }}</div>
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">Estado Actual: <strong>{{ ordersStore.currentPedido.estado }}</strong></li>
          </ul>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="changeOrderState">
            <div class="mb-3">
              <label for="orderState" class="form-label">Cambiar Estado del Pedido</label>
              <select id="orderState" class="form-select" v-model="selectedState">
                <option value="nuevo">Nuevo</option>
                <option value="pendiente">Pendiente</option>
                <option value="completado">Completado</option>
              </select>
            </div>
            <button type="submit" class="btn btn-primary">Cambiar Estado</button>
          </form>
        </div>
      </div>
      <!-- Status Message -->
      <div v-if="statusMessage" class="alert mt-4" :class="statusClass">
        {{ statusMessage }}
      </div>
    </div>
    <div v-else>
      <p class="text-center mt-4">El pedido no existe o no se pudo cargar.</p>
    </div>
  </div>
</template>


<script>
import { useOrdersStore } from '@/stores/order';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'OrderDetails',
  props: {
    id: [Number, String]
  },
  setup(props) {
    const ordersStore = useOrdersStore();
    const router = useRouter();
    const selectedState = ref('');
    const statusMessage = ref('');
    const statusClass = ref('');

    onMounted(async () => {
      await ordersStore.fetchPedidoById(props.id);
      if (!ordersStore.currentPedido) {
        router.push('/error-404');
      } else {
        selectedState.value = ordersStore.currentPedido.estado;
      }
    });

    async function changeOrderState() {
      try {
        await ordersStore.updateOrderState(props.id, selectedState.value);
        await ordersStore.fetchPedidoById(props.id);
        statusMessage.value = "Estado del pedido actualizado con éxito.";
        statusClass.value = "alert-success";
      } catch (error) {
        console.error("Error updating order state:", error);
        statusMessage.value = "Error al actualizar el estado del pedido.";
        statusClass.value = "alert-danger";
      }
    }

    return {
      ordersStore,
      selectedState,
      changeOrderState,
      statusMessage,
      statusClass,
    };
  },
};
</script>
