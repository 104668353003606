<template>
    <div class="error-page">
      <h1>Error 404: Página No Encontrada</h1>
      <p>Lo sentimos, la página que estás buscando no existe.</p>
      <router-link to="/">Volver al inicio</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Error404Component',
  }
  </script>
  
  <style>
  .error-page {
    text-align: center;
    margin-top: 50px;
  }
  </style>
  