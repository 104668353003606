import axios from 'axios';
import { defineStore } from 'pinia';

export const useCategoriesStore = defineStore('categories', {
  state: () => ({
    allCategories: [],
    categories: [],
    currentPage: 1,
    totalPages: 0,
    limit: 5,
    nextPage: null,
    prevPage: null,
    categoryDetails: {
      productos: [],
      currentPage: 1,
      totalPages: 0,
      limit: 5,
      nextPage: null,
      prevPage: null,
  }
  }),
  actions: {
    async fetchAllCategories() {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/categorias`, {
        });

        if (response.status === 200) {
          this.allCategories = response.data.items;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async createCategory(category) {
      try {
        const response = await axios.post(`https://silkroad-server.carlosrobles.es/api/v1/categorias`, category, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
    
        if (response.status === 200) {
          this.fetchCategories();
        }
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async updateCategory(category) {
      try {
        const response = await axios.put(`https://silkroad-server.carlosrobles.es/api/v1/categorias/${category.id}`, category, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
    
        if (response.status === 200) {
          this.fetchCategories();
        }
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },    
    async deleteCategory(categoryId) {
      try {
        const response = await axios.delete(`https://silkroad-server.carlosrobles.es/api/v1/categorias/${categoryId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (response.status === 200) {
          this.fetchCategories();
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },  
    async fetchCategoryById(categoryId, page, limit) {
      try {
        let url = `https://silkroad-server.carlosrobles.es/api/v1/categorias/${categoryId}`;
        if (page && limit) {
          url += `?page=${page}&limit=${limit}`;
        }
    
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
    
        if (response.status === 200) {
          this.categoryDetails.productos = response.data.category.productos;
          if (page && limit) {
            this.categoryDetails.currentPage = response.data.currentPage;
            this.categoryDetails.totalPages = response.data.totalPages;
            this.categoryDetails.nextPage = response.data.nextPage;
            this.categoryDetails.prevPage = response.data.prevPage;
          }
          return response.data.category;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCategories(page = 1) {
        try {
          const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/categorias?page=${page}&limit=${this.limit}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
          });
  
          if (response.status === 200) {
            const data = response.data;
            this.allCategories = data.items;
            this.categories = data.items;
            this.currentPage = data.currentPage;
            this.totalPages = data.totalPages;
            this.nextPage = data.nextPage;
            this.prevPage = data.prevPage;
          }
        } catch (error) {
          console.error(error);
        }
      }, 
    async filterCategories(query) {
        await this.fetchAllCategories();
        this.categories = this.allCategories.filter(category =>
            category.nombre.toLowerCase().includes(query.toLowerCase()) ||
            category.id === parseInt(query)
        );
    },
    async filterProduct(categoryId, query) {
      await this.fetchCategoryById(categoryId);
      if (query) {
        this.categoryDetails.productos = this.categoryDetails.productos.filter(product =>
          product.nombre.toLowerCase().includes(query.toLowerCase()) ||
          product.descripcion.toLowerCase().includes(query.toLowerCase()) ||
          product.id === parseInt(query)
        );
      }
    }   
  }
});
