import axios from 'axios';
import { defineStore } from 'pinia';

export const useProductsStore = defineStore('products', {
  state: () => ({
    allProducts: [],
    products: [],
    currentPage: 1,
    totalPages: 0,
    limit: 6,
    nextPage: null,
    prevPage: null
  }),
  actions: {
    async fetchAllProducts() {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/productos`);

        if (response.status === 200) {
          this.allProducts = response.data.items;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchProductById(productId) {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/productos/${productId}`);
    
        if (response.status === 200 && response.data) {
          return response.data;
        }
      } catch (error) {
        return null;
      }
      return null;
    },      
    async fetchProducts(page = 1) {
        try {
          const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/productos?page=${page}&limit=${this.limit}`);
  
          if (response.status === 200) {
            const data = response.data;
            this.allProducts = data.items;
            this.products = data.items;
            this.currentPage = data.currentPage;
            this.totalPages = data.totalPages;
            this.nextPage = data.nextPage;
            this.prevPage = data.prevPage;
          }
        } catch (error) {
          console.error(error);
        }
      }, 
      async fetchProductsByCategory(categoryId, page = 1) {
        try {
          const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/productos-cat?categoriaId=${categoryId}&page=${page}&limit=${this.limit}`);
  
          if (response.status === 200) {
            const data = response.data;
            this.products = data.items;
            this.currentPage = data.currentPage;
            this.totalPages = data.totalPages;
            this.nextPage = data.nextPage;
            this.prevPage = data.prevPage;
          }

        } catch (error) {
          console.error(error);
        }
      },
      async filterProducts(query) {
        await this.fetchAllProducts();
        this.products = this.allProducts.filter(product =>
          product.nombre.toLowerCase().includes(query.toLowerCase()) ||
          product.descripcion.toLowerCase().includes(query.toLowerCase()) ||
          product.id === parseInt(query)
        );
      },
      async createProduct(product) {
        try {
          const response = await axios.post(`https://silkroad-server.carlosrobles.es/api/v1/productos`, product, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              'Content-Type': 'multipart/form-data'
            }
          });
  
          if (response.status === 200) {
            this.fetchAllProducts();
          }
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
  
      async updateProduct(product) {
        try {
          const response = await axios.put(`https://silkroad-server.carlosrobles.es/api/v1/productos/${product.id}`, product, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              'Content-Type': 'multipart/form-data'
            }
          });
  
          if (response.status === 200) {
            this.fetchAllProducts();
          }
          return response;
        } catch (error) {
          console.error(error);
          throw error;
        }
      },
      async deleteProduct(productId) {
        try {
          const response = await axios.delete(`https://silkroad-server.carlosrobles.es/api/v1/productos/${productId}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
          });
  
          if (response.status === 200) {
            this.fetchProducts();
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      }, 
  }
});
