<template>
    <div class="container mx-auto mt-5" style="width: 30%">
      <h3>{{ isEditing ? 'Editar Categoría' : 'Crear Categoría' }}</h3>
      <form @submit.prevent="saveCategory">
        <div class="form-group mt-4">
          <label for="name">Nombre de la Categoría</label>
          <input v-model="formData.name" type="text" class="form-control" id="name" />
        </div>

        <!-- Agrega más campos si son necesarios -->

        <button type="submit" class="btn btn-primary mt-3">
          {{ isEditing ? 'Actualizar' : 'Crear' }}
        </button>
      </form>

      <div v-if="message" :class="{'text-success': isSuccess, 'text-danger': !isSuccess, 'mt-3': true}">
        {{ message }}
      </div>
    </div>
  </template>

  
<script>
import { useCategoriesStore } from '@/stores/category'

export default {
    name: 'CategoryForm',
    props: {
        id: {
        type: [Number, String],
        default: null
        }
    },
    data() {
        return {
        formData: {
            name: ''
        },
        message: '',
        isSuccess: false
        };
    },
    setup() {
        const categoriesStore = useCategoriesStore();
        return {
            categoriesStore
        };
    },
    computed: {
        isEditing() {
            return this.id !== null && this.id !== undefined && this.id !== '';
        },
    },
  methods: {
    async loadCategory() {
      const category = await this.categoriesStore.fetchCategoryById(this.id);
      if (category) {
        this.formData.name = category.nombre;
      }
    },
    async saveCategory() {
        const trimmedName = this.formData.name.trim();

        if (!trimmedName) {
            this.message = 'El nombre de la categoría es obligatorio.';
            this.isSuccess = false;
            return;
        }

        if (trimmedName.length > 100) {
            this.message = 'El nombre de la categoría no debe superar los 20 caracteres.';
            this.isSuccess = false;
            return;
        }

        try {
            if (this.isEditing) {
                await this.categoriesStore.updateCategory({ nombre: trimmedName, id: this.id });
                this.message = 'Categoría actualizada con éxito.';
            } else {
                await this.categoriesStore.createCategory({ nombre: trimmedName });
                this.message = 'Categoría creada con éxito.';
            }
                this.isSuccess = true;
        } catch (error) {
            this.message = error.response?.data?.error || 'Ocurrió un error al guardar la categoría.';
            this.isSuccess = false;
        }
    }
  },
  created() {
    if (this.isEditing) {
      this.loadCategory();
    }
  }
}
</script>
