import axios from 'axios';
import { defineStore } from 'pinia';

export const useOrdersStore = defineStore('orders', {
  state: () => ({
    allOrders: [],
    orders: [],
    currentPage: 1,
    totalPages: 0,
    limit: 5,
    nextPage: null,
    prevPage: null,
    currentPedido: null
  }),
  actions: {
    async fetchAllOrders() {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/pedidos`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (response.status === 200) {
          this.allOrders = response.data.items;
        }

        console.log(this.allOrders)
      } catch (error) {
        console.error(error);
      }
    },   
    async fetchPedidosByUserId(userId, page = 1) {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/pedidos/usuario/${userId}?page=${page}&limit=${this.limit}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
  
        if (response.status === 200) {
          const data = response.data;
          // Actualiza el estado con los pedidos del usuario y la información de paginación
          this.orders = data.items; // Asume que la respuesta contiene un array de pedidos
          this.currentPage = data.currentPage;
          this.totalPages = data.totalPages;
          this.nextPage = data.nextPage;
          this.prevPage = data.prevPage;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPedidoById(pedidoId) {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/pedidos/${pedidoId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
  
        if (response.status === 200) {
          this.currentPedido = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchOrders(page = 1) {
        try {
          const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/pedidos?page=${page}&limit=${this.limit}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
          });
  
          if (response.status === 200) {
            const data = response.data;
            this.allOrders = data.items;
            this.orders = data.items;
            this.currentPage = data.currentPage;
            this.totalPages = data.totalPages;
            this.nextPage = data.nextPage;
            this.prevPage = data.prevPage;
          }

        } catch (error) {
          console.error(error);
        }
      }, 
    async filterOrders(query) {
        await this.fetchAllOrders();
        this.orders = this.allOrders.filter(order =>
            order.usuario.name.toLowerCase().includes(query.toLowerCase()) ||
            order.id === parseInt(query)
        );
    },
    async fetchPedidosAuth(page = 1) {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/mis-pedidos?page=${page}&limit=${this.limit}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
  
        if (response.status === 200) {
          const data = response.data;
          this.orders = data.items;
          this.currentPage = data.currentPage;
          this.totalPages = data.totalPages;
          this.nextPage = data.nextPage;
          this.prevPage = data.prevPage;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async createOrder(orderData) {
      try {
        const response = await axios.post(`https://silkroad-server.carlosrobles.es/api/v1/pedidos`, orderData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        this.fetchPedidosAuth();
        return response;
      } catch (error) {
        console.error("Error creating order:", error);
        throw error;
      }
    },
    async updateOrderState(pedidoId, estado) {
      try {
        const response = await axios.patch(`https://silkroad-server.carlosrobles.es/api/v1/pedidos/${pedidoId}`, 
        { estado }, 
        {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('authToken')}` }
        });
        
        this.fetchPedidosAuth();
        return response;
      } catch (error) {
        console.error("Error updating order state:", error);
        throw error; // or handle error as needed
      }
    },
    async addProductsToOrder(pedidoId, productsToAdd) {
      try {
        const response = await axios.post(`https://silkroad-server.carlosrobles.es/api/v1/pedidos/${pedidoId}/productos-add`, productsToAdd, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async removeProductsFromOrder(pedidoId, productIdsToRemove) {
      try {
        const response = await axios.delete(`https://silkroad-server.carlosrobles.es/api/v1/pedidos/${pedidoId}/productos-rem`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          data: { productIds: productIdsToRemove }
        });
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async deleteOrder(pedidoId, mode) {
      try {
        const response = await axios.delete(`https://silkroad-server.carlosrobles.es/api/v1/pedidos/${pedidoId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
        if (response.status === 200 && mode) {
          this.fetchOrders();
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
});
