import axios from 'axios';
import { defineStore } from 'pinia';

export const useUsersStore = defineStore('users', {
  state: () => ({
    allUsers: [],
    users: [],
    currentPage: 1,
    totalPages: 0,
    limit: 5,
    nextPage: null,
    prevPage: null,
  }),
  actions: {
    async fetchAllUsers() {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/usuarios`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (response.status === 200) {
          this.allUsers = response.data.items;
          console.log(this.allUsers);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUserById(userId) {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/usuarios/${userId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });
  
        if (response.status === 200) {
          return response.data.user;
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async fetchUsers(page = 1) {
      try {
        const response = await axios.get(`https://silkroad-server.carlosrobles.es/api/v1/usuarios?page=${page}&limit=${this.limit}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (response.status === 200) {
          const data = response.data;
          this.allUsers = data.items;
          this.users = data.items;
          this.currentPage = data.currentPage;
          this.totalPages = data.totalPages;
          this.nextPage = data.nextPage;
          this.prevPage = data.prevPage;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateUser(id, updatedUserData) {
      try {
        await axios.put('https://silkroad-server.carlosrobles.es/api/v1/usuarios/' + id, updatedUserData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'multipart/form-data'
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async filterUsers(query) {
      await this.fetchAllUsers();
      this.users = this.allUsers.filter(user =>
        user.name.toLowerCase().includes(query.toLowerCase()) ||
        user.username.toLowerCase().includes(query.toLowerCase()) ||
        user.email.toLowerCase().includes(query.toLowerCase()) ||
        user.id === parseInt(query)
      );
    },
    async deleteUser(usuarioId) {
      try {
        const response = await axios.delete(`https://silkroad-server.carlosrobles.es/api/v1/usuarios/${usuarioId}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          }
        });

        if (response.status === 200) {
          this.fetchUsers();
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },     
  }
});
