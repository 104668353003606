<template>
    <div class="container mt-5" v-if="ordersStore.currentPedido != null">
      <div class="card">
        <div class="card-header">
          Detalles del Pedido
        </div>
        <div class="d-flex">
          <ul class="list-group list-group-flush flex-grow-1">
            <li class="list-group-item">ID: {{ ordersStore.currentPedido.id }}</li>
            <li class="list-group-item">Nombre: {{ ordersStore.currentPedido.usuario.name }}</li>
            <li class="list-group-item">Username: {{ ordersStore.currentPedido.usuario.username }}</li>
            <li class="list-group-item">Email: {{ ordersStore.currentPedido.usuario.email }}</li>
            <li class="list-group-item">Dirección: {{ ordersStore.currentPedido.usuario.address }}</li>
            <li class="list-group-item">Fecha y hora: {{ ordersStore.currentPedido.fechaHora }}</li>
            <li class="list-group-item">Estado: {{ ordersStore.currentPedido.estado }}</li>
          </ul>
          </div>
      </div>
    </div>
    <div v-else>
      <p class="text-center mt-4">El pedido no existe.</p>
    </div>

    <div class="container mt-5" v-if="productosPaginaActual.length > 0">
      <div class="card">
        <div class="card-header">
          Productos del Pedido
        </div>
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Precio</th>
                <th>Cantidad</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="producto in productosPaginaActual" :key="producto.id">
                <td>{{ producto.id }}</td>
                <td>{{ producto.nombre }}</td>
                <td>{{ producto.descripcion }}</td>
                <td>{{ producto.precio }}€</td>
                <td>{{ producto.ProductoPedido.cantidad }}</td>
                <td>
                  <router-link :to="{ name: 'ProductDetails', params: { id: producto.id } }" class="btn btn-primary">Ver Detalles</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer d-flex justify-content-end" v-if="productosPaginaActual">
            <nav aria-label="Page navigation" class="mt-3">
              <ul class="pagination">
                <li class="page-item" :class="{ disabled: paginaActual === 1 }">
                  <a class="page-link" href="#" @click="cambiarPagina(paginaActual - 1)">Anterior</a>
                </li>
                <li class="page-item" :class="{ active: n === paginaActual }" v-for="n in totalPaginas" :key="n">
                  <a class="page-link" href="#" @click="cambiarPagina(n)">{{ n }}</a>
                </li>
                <li class="page-item" :class="{ disabled: paginaActual === totalPaginas }">
                  <a class="page-link" href="#" @click="cambiarPagina(paginaActual + 1)">Siguiente</a>
                </li>
              </ul>
            </nav>
        </div> 
      </div> 
    </div>
    <div class="container mt-5 mb-5" v-if="productosPaginaActual">
      <div class="card">
        <div class="card-header">
          Resumen del pedido
        </div>
        <div class="card-body">
          <p>Total de productos: {{ totalCantidad }}</p>
          <p>Total a pagar: <b>{{ totalPrice.toFixed(2) }}€</b></p>
        </div>
      </div> 
    </div> 
  </template>
  
  

<script>
import { useOrdersStore } from '@/stores/order';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'OrderDetails',
  props: {
    id: [Number, String]
  },
  data() {
    return {
      paginaActual: 1,
      productosPorPagina: 5,
    };
  },
  setup(props) { 
    const ordersStore = useOrdersStore();
    const router = useRouter();

    onMounted(async () => {
      await ordersStore.fetchPedidoById(props.id); 
      if(ordersStore.currentPedido == null) {
        router.push('/error-404');
      }
    });

    return { ordersStore };
  },
  methods: {
    cambiarPagina(nuevaPagina) {
      this.paginaActual = nuevaPagina;
    }
  },
  computed: {
    productosPaginaActual() {
      if (this.ordersStore.currentPedido && this.ordersStore.currentPedido.productos) {
        let inicio = (this.paginaActual - 1) * this.productosPorPagina;
        let fin = inicio + this.productosPorPagina;
        return this.ordersStore.currentPedido.productos.slice(inicio, fin);
      }
      return [];
    },
    totalPaginas() {
      if (this.ordersStore.currentPedido && this.ordersStore.currentPedido.productos) {
        return Math.ceil(this.ordersStore.currentPedido.productos.length / this.productosPorPagina);
      }
      return 0;
    },
    totalCantidad() {
      if (this.ordersStore.currentPedido && this.ordersStore.currentPedido.productos) {
        return this.ordersStore.currentPedido.productos.reduce((total, producto) => {
          return total + producto.ProductoPedido.cantidad;
        }, 0);
      }
      return 0;
    },
    totalPrice() {
      if (this.ordersStore.currentPedido && this.ordersStore.currentPedido.productos) {
        return this.ordersStore.currentPedido.productos.reduce((total, producto) => {
          return total + (producto.precio * producto.ProductoPedido.cantidad);
        }, 0);
      }
      return 0;
    }
  }
};
</script>
