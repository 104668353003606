<template>
    <div class="container mx-auto mt-5" style="width: 50%">
        <h3>{{ isEditing ? 'Editar Producto' : 'Crear Producto' }}</h3>
        <form @submit.prevent="saveProduct">
            <div class="form-group mt-4">
                <label for="productName">Nombre del Producto</label>
                <input v-model="formData.nombre" type="text" class="form-control" id="productName" />
            </div>

            <div class="form-group mt-4">
                <label for="productDescription">Descripción</label>
                <textarea v-model="formData.descripcion" class="form-control" id="productDescription"></textarea>
            </div>

            <div class="form-group mt-4">
                <label for="productPrice">Precio</label>
                <input v-model.number="formData.precio" type="number" class="form-control" id="productPrice" step="0.01" />
            </div>

            <div class="form-group mt-4">
                <label for="productStock">Stock</label>
                <input v-model.number="formData.stock" type="number" class="form-control" id="productStock" />
            </div>

            <div class="form-group mt-4">
                <label for="productCategory">Categoría</label>
                <select v-model="formData.categoriaId" class="form-control" id="productCategory">
                    <option value="" disabled>Seleccione una categoría</option>
                    <option v-for="category in categoriesStore.allCategories" :key="category.id" :value="category.id">
                        {{ category.nombre }}
                    </option>
                </select>
            </div>

            <div class="image-upload-row mt-5">
                <!-- Contenedor de la Imagen del Producto -->
                <!-- Contenedor de la Imagen del Producto -->
                <div class="image-container" v-if="imagePreview">
                    <div class="card">
                        <div class="user-image" :style="{ backgroundImage: 'url(' + imagePreview + ')' }"></div>
                    </div>
                </div>

                <!-- Input para Cargar una Nueva Imagen -->
                <div class="mb-3">
                    <label for="image" class="form-label">Imagen</label>
                    <input type="file" class="form-control" id="image" @change="handleFileUpload">
                </div>

            </div>

            <div v-if="message" :class="{'text-success': isSuccess, 'text-danger': !isSuccess, 'mt-3': true}">
                {{ message }}
            </div>

            <div class="button-container mb-5">
                <button type="submit" class="btn btn-primary mt-3">
                    {{ isEditing ? 'Actualizar' : 'Crear' }}
                </button>
            </div>
        </form>

    </div>
</template>

<script>
import { useProductsStore } from '@/stores/product';
import { useCategoriesStore } from '@/stores/category';
import { getImageSrc } from '@/library';


export default {
    name: 'ProductForm',
    props: {
        id: {
            type: [Number, String],
            default: null
        }
    },
    data() {
        return {
            formData: {
                nombre: '',
                descripcion: '',
                precio: null,
                stock: null,
                categoriaId: null,
                imageFile: null
            },
            imagePreview: null,
            message: '',
            isSuccess: false
        };
    },
    setup() {
        const productsStore = useProductsStore();
        const categoriesStore = useCategoriesStore();
        categoriesStore.fetchAllCategories();
        return { productsStore, categoriesStore };
    },
    computed: {
        isEditing() {
            return this.id !== null && this.id !== undefined && this.id !== '';
        }
    },
    methods: {
        async loadProduct() {
            const product = await this.productsStore.fetchProductById(this.id);
            product.image = getImageSrc(product.image) ?? null;
            if (product) {
                this.formData = { ...product };
                this.imagePreview = product.image;
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.formData.image = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        async saveProduct() {
            if (!this.validateProduct()) {
                return;
            }

            const productData = {
                id: this.id,
                nombre: this.formData.nombre,
                descripcion: this.formData.descripcion,
                precio: this.formData.precio,
                stock: this.formData.stock,
                categoriaId: this.formData.categoriaId,
            };

            if (this.formData.image && this.formData.image instanceof File) {
                productData.image = this.formData.image;
            }

            try {
                if (this.isEditing) {
                    await this.productsStore.updateProduct(productData, this.id);
                    this.message = 'Producto actualizado con éxito.';
                } else {
                    await this.productsStore.createProduct(productData);
                    this.message = 'Producto creado con éxito.';
                }
                this.isSuccess = true;
            } catch (error) {
                this.message = error.response?.data?.error || 'Ocurrió un error al guardar el producto.';
                this.isSuccess = false;
            }
        },
        validateProduct() {
            const { nombre, descripcion, precio, stock } = this.formData;
            if (!nombre.trim() || precio == null || stock == null || descripcion == null) {
                this.message = 'Los campos nombre, precio y stock son obligatorios.';
                this.isSuccess = false;
                return false;
            }
            
            if (nombre.length > 100) {
                this.message = 'El nombre de la categoría no debe superar los 20 caracteres.';
                this.isSuccess = false;
                return false;
            }

            if (descripcion > 1000) {
                this.message = 'La descripción del producto no debe superar los 1000 caracteres.';
                this.isSuccess = false;
                return false;
            }

            if(precio < 0) {
                this.message = 'El precio del producto debe ser mayor a 0.';
                this.isSuccess = false;
                return false;
            }

            if(stock < 0) {
                this.message = 'El stock del producto debe ser mayor a 0.';
                this.isSuccess = false;
                return false;
            }
            return true;
        }
    },
    created() {
        if (this.isEditing) {
            this.loadProduct();
        }
    }
};
</script>


<style scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
}

.user-image {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

.image-upload-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.image-container {
  margin-right: 20px;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
</style>