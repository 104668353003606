<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <a class="navbar-brand ms-3" href="/">SilkRoad
        <img src="../../assets/logo.png" alt="Logo" height="40">
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
      <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" href="/store">Tienda</a>
        </li>
        <li class="nav-item active" v-if="!isAuthenticated">
          <a class="nav-link" href="/register">Registro</a>
        </li>
        <li class="nav-item active" v-if="!isAuthenticated">
          <a class="nav-link" href="/login">Iniciar sesión</a>
        </li>
        <li class="nav-item active" v-if="isAuthenticated">
          <a class="nav-link" href="/account">Mi cuenta</a>
        </li>
        <li class="nav-item active" v-if="isAuthenticated && isAdmin">
          <a class="nav-link" href="/dashboard">Dashboard</a>
        </li>
        <li class="nav-item active" v-if="isAuthenticated">
          <a class="nav-link" href="/" @click="logout">Cerrar sesión</a>
        </li>
      </ul>
    </div>
    </nav>
  </template>

<script>
import { useAuthStore } from '@/stores/auth';
import { computed, onMounted } from 'vue';

export default {
  name: 'AppNavbar',
  setup() {
    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const isAdmin = computed(() => authStore.user.admin);

    onMounted(() => {
      authStore.checkAuth();
    });

    const logout = () => {
      authStore.logout();
    };

    return { isAuthenticated, isAdmin, logout };
  },
};
</script>
