import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { authRoutes } from './routes/authRoutes';
import { mainRoutes } from './routes/mainRoutes';
import Error401 from '@/components/errors/Error-401.vue';
import Error403 from '@/components/errors/Error-403.vue';
import Error404 from '@/components/errors/Error-404.vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...authRoutes,
    ...mainRoutes,
    { path: '/error-401', component: Error401 },
    { path: '/error-403', component: Error403 },
    { path: '/:catchAll(.*)', component: Error404 },
  ],
});

router.beforeEach((to, from, next) => {
  const store = useAuthStore();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.isAuthenticated) {
      next({ path: '/error-401' });
    } else if (to.matched.some(record => record.meta.requiresAdmin) && !store.user.admin) {
      next({ path: '/error-403' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
